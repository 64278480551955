import React from 'react'

import Icon from './Icon'

const Cta = () => {
  return (
    <div className="cta">
      <div className="cta__inner">
        <h4>Consulta gli iscritti all'Albo</h4>
        <a
          href="https://www.organismocf.it/portal/web/portale-ocf/ricerca-nelle-sezioni-dell-albo"
          target="_blank"
          rel="noreferrer"
          className="button button--with-icon"
        >
          Vai <Icon type="arrow" />
        </a>
      </div>
    </div>
  )
}

export default Cta
