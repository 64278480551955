import React, {useState, useRef} from 'react'
import {useCookiesConsent} from '../contexts/CookiesContext'
import play from '../images/play.svg'

const Video = ({ytID, video, poster, small}) => {
  const cookies = useCookiesConsent()
  const [plaing, setPlaing] = useState(false)

  const myVideo = useRef(null)

  const handleClick = () => {
    myVideo.current.play()
    myVideo.current.controls = true
    setPlaing(true)
  }

  if (!cookies.analytics && ytID) {
    return (
      <div className="video-placeholder">
        <a
          href={`https://www.youtube.com/watch?v=${ytID}`}
          target="_blank"
          rel="noreferrer"
          className="video-placeholder__inner"
          style={{backgroundImage: 'url(' + poster + ')'}}
        >
          Guarda il video
        </a>
      </div>
    )
  }

  return (
    <div className="video">
      <div className={`video__inner ${plaing ? 'plaing' : ''}`}>
        {video && (
          <>
            <video ref={myVideo} poster={poster} role="video">
              <source src={video} />
            </video>
            <div
              className={`video__play ${small ? 'small' : ''}`}
              onClick={handleClick}
            >
              <img src={play} alt="play" />
            </div>
          </>
        )}
        {ytID && (
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube-nocookie.com/embed/${ytID}?rel=0`}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )}
      </div>
    </div>
  )
}

export default Video
