import React from 'react'
import Icon from './Icon'
import Mouse from './Mouse'

const Cover = ({icon, title, children, color, bg, scroll, link}) => {
  return (
    <div
      className={`cover cover--${color}`}
      style={{backgroundImage: 'url(' + bg + ')'}}
    >
      <div className="cover__inner">
        <div className="cover__content">
          {icon && <img className="cover__icon" src={icon} alt={title} />}
          <h1 className="cover__title">{title}</h1>
          <div className="cover__excerpt">{children}</div>
          {scroll && <Mouse />}
          {link && (
            <div className="cover__link">
              <a className="button" href={link}>
                Guarda il video <Icon type="arrowDown" />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Cover
