import React from 'react'

import Icon from './Icon'

const Mouse = ({dark}) => {
  return (
    <div className={`mouse ${dark ? 'dark' : ''}`}>
      <div className="mouse__scroller"></div>
      <Icon type={dark ? 'mouseDark' : 'mouse'} big />
    </div>
  )
}

export default Mouse
