import React from 'react'

import Video from './Video'

const VideoBox = ({ytID, video, poster}) => {
  return (
    <section id="video" className="video-box">
      <div className="video-box__inner">
        <h4>Informati</h4>
        <Video ytID={ytID} video={video} poster={poster} />
      </div>
    </section>
  )
}

export default VideoBox
