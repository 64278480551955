import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

import Cover from '../components/Cover'
import VideoBox from '../components/VideoBox'
import CTA from '../components/Cta'

import coverImage from '../images/finanza-impulsivita.jpg'

import posterImpulsivita from '../images/poster-impulsivita.png'

const Impulsivita = () => (
  <Layout>
    <SEO title="Finanza ed Impulsività" />
    <Cover
      title="Finanza ed Impulsività"
      color="white"
      bg={coverImage}
      icon=""
      link="#video"
    >
      <p>
        Forse potresti scegliere una cravatta o una camicia solo perché ti piace
        il colore, ma di certo non useresti lo stesso criterio per uno strumento
        di precisione, o no? Il breve video qui sotto ti rammenta i motivi per
        cui è sconsigliabile affrontare con impulsività o, peggio, con un po’ di
        presunzione, la questione investimenti. Competenza e ponderazione sono
        molto più efficaci.
      </p>
    </Cover>
    <VideoBox ytID="nIcb2dEyWoY" poster={posterImpulsivita} />
    <CTA />
  </Layout>
)

export default Impulsivita
